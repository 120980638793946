.functionalityFeatures {
  background-color: var(--very-light-gray);
}

.functionalityFeatures-heading {
  margin-bottom: 20px;
}

.functionalityFeatures-heading h2 {
  font-size: 36px;
  font-weight: 700;
}
