.pricingBenefitCard {
  text-align: left !important;
  color: var(--dark-gray) !important;
}

.pricingBenefitCard-icon {
  margin-bottom: 10px;
}

.pricingBenefitCard-icon img {
  background-color: var(--pale-green);
  padding: 7px 9px;
  border-radius: 999em;
}

.pricingBenefitCard h3 {
  font-size: 20px;
  font-weight: 700;
}

.pricingBenefitCard p {
  font-size: 17px !important;
  margin-top: 8px;
}

@media screen and (max-width: 749px) {
  .pricingBenefitCard {
    padding: 0 2px !important;
  }
}
