.logoOption {
  display: flex;
  align-items: center;
  padding: 5px 6px;
  text-align: left;
  width: auto !important;
  border-radius: 7px;
  text-decoration: none !important;
}

.logoOption:hover {
  background-color: var(--options-hover);
}

.logoOption-icon img {
  background-color: var(--icon-bg);
  padding: 8px 7px;
  border-radius: 999em;
}

.logoOption-content h5 {
  font-size: 16px;
  margin: 0;
  margin-left: 8px;
  color: var(--light-black);
}

.logoOption-content-newTag {
  font-size: 11px;
  color: #ffffff;
  background-color: #7cc576;
  margin-left: 5px;
  padding: 1px 8px;
  border-radius: 10px;
}

@media screen and (max-width: 990px) {
  .logoOption-content h5 {
    font-size: 14px;
  }
}
