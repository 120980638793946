.navbar {
  background-color: #ffffff !important;
  transition: all 0.4s;
}

.scrolled {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
}

.navbar-toggler {
  border: none !important;
  outline: none !important;
}

.navbar-toggler:hover {
  border: none !important;
}

.nav-toggler-icon {
  color: var(--main-color);
}

.mobile-nav-link-icon {
  padding: 9px 8px;
  background-color: var(--icon-bg);
  border-radius: 99em;
  margin-right: 10px;
}

.icon-and-text > img {
  display: none;
}

.more-icon {
  display: none;
}

.brand {
  display: flex !important;
  align-items: center !important;
}

.navbar-brand {
  color: var(--main-color) !important;
  font-weight: 700;
  font-size: 28px !important;
}

.navbar ul {
  margin-left: auto;
  margin-right: auto;
}

.nav-item {
  margin: 0 7px;
}

.nav-link {
  color: var(--light-black) !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.nav-link:hover {
  color: rgb(87, 92, 99) !important;
}

.nav-link-country {
  display: flex;
  align-items: center;
}

.nav-link-icon {
  width: 22px;
  height: 17px;
  margin-right: 5px;
}

.login-signup-btns {
  display: flex;
}

.login-signup-btns .login-btn {
  text-decoration: none !important;
}

.login-signup-btns .signup-btn {
  padding: 7px 16px;
  font-size: 15px !important;
  font-weight: 500 !important;
  background-color: var(--main-color);
  color: #ffffff !important;
  border-radius: 40px;
  margin-left: 20px;
}

.login-signup-btns .signup-btn:hover {
  background-color: var(--main-color);
}

.category-heading {
  font-size: 20px;
  padding-left: 12px;
  margin-bottom: 10px;
}

.drop-down {
  position: relative;
  cursor: pointer;
}

.drop-down-menu {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
}

.drop-down-menu .tip {
  position: relative;
  margin-bottom: -5px;
}

.drop-down-options {
  text-align: left;
  background-color: #ffffff;
  border-radius: 10px;
  color: var(--light-black);
  box-shadow: 0 0px 18px -3px rgba(0, 0, 0, 0.2),
    0 0px 14px -4px rgba(0, 0, 0, 0.2);
}

.drop-down-menu {
  display: none;
}

.drop-down-menu-show {
  display: block;
}

.back-btn {
  position: absolute !important;
  top: 10px !important;
  left: 0px;
  font-size: 16px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 35px 10px 15px;
}

.back-btn button {
  background: transparent;
  border: none;
  color: var(--gray);
}

.back-btn span {
  font-weight: 600 !important;
}

/* Navbar sliding functionality and Responsive */

@media screen and (max-width: 990px) {
  .mobile-menu {
    position: fixed;
    top: 55px;
    right: -100%; /* Start offscreen */
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #ffffff;
    padding: 15px;
    transition: right 0.3s; /* Transition the 'right' property */
  }

  .show-menu {
    right: 0; /* Slide in from the right */
  }

  .mobile-dropDown {
    position: absolute;
    top: -30px !important;
    right: -100% !important; /* Start offscreen */
    width: 100% !important;
    height: 100vh !important;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #ffffff;
    padding-top: 10px;
    transition: right 0.3s; /* Transition the 'right' property */
    z-index: 100;
  }

  .mobile-dropDown-show {
    display: block;
    right: 0;
  }

  /* .drop-down:hover .drop-down-menu {
    position: absolute !important;
    display: block;
    top: 0px !important;
    right: 0 !important; 
    width: 100% !important;
    height: 100vh !important;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #ffffff;
    padding-top: 10px;
    transition: right 0.3s;
    z-index: 1000;
  } */

  .drop-down-options {
    box-shadow: none;
  }

  .navbar .container-fluid {
    padding: 0 !important;
  }

  .brand,
  .navbar-toggler {
    padding: 0 15px;
  }

  .nav-item {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid rgb(209, 209, 209);
    /* padding-left: 15px;
    padding-right: 15px; */
    margin: 0 !important;
  }

  .nav-link {
    font-size: 18px;
    font-weight: 600 !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }

  .icon-and-text > img {
    display: inline;
  }

  .more-icon {
    display: inline;
  }

  .icon-and-text-country {
    display: flex;
    align-items: center;
  }
  .icon-and-text-country > .nav-link-icon {
    width: 38px;
    height: 38px;
    padding: 9px 8px;
    background-color: var(--icon-bg);
    border-radius: 99em;
    margin-right: 10px;
  }

  .login-signup-btns {
    display: block !important;
  }

  .login-btn {
    display: flex;
    width: 100%;
  }

  .signup-btn {
    display: block;
    width: 90%;
    padding: 16px !important;
    margin: 15px 0 0 0 !important;
    border-radius: 7px !important;
  }

  .signup-btn > span {
    font-size: 18px !important;
  }

  .tip {
    display: none !important;
  }

  .drop-down-options {
    display: block !important;
  }

  /* .drop-down-options .category {
    width: 100% !important;
  } */

  .signup-btn {
    margin: 15px !important;
    text-align: center;
  }
}

/* Ends */
