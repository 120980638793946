.pricingCard {
  width: 468px;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0 2px 20px 3px rgba(218, 218, 218, 0.664);
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.pricingCard-sliding-btn {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
  padding: 5px;
  height: 60px;
  border-radius: 40px;
  cursor: pointer;
  box-shadow: inset 0 0 13px rgba(0, 0, 0, 0.2);
}

.pricingCard-sliding-btn .slide {
  font-size: 16px;
  font-weight: 600;
  padding: 13px 20px;
  border-radius: 40px;
  width: 50%;
}

.pricingCard-sliding-btn .slide-1 {
  background-color: var(--dark-gray);
  color: #ffffff;
  transition: all 0.2s;
}
.pricingCard-sliding-btn .slide-2 {
  color: #000000;
}

.oneLocation-content > h3,
.enterprise-content h3 {
  font-size: 22px;
  font-weight: 700;
  margin-top: 35px;
}

.oneLocation-content,
.enterprise-content {
  display: none;
}

.pricingCard .show-slide {
  display: block;
}

.oneLocation-content-price {
  display: flex;
  justify-content: center;
  height: fit-content;
}

.oneLocation-content-price .currency {
  font-size: 30px;
  font-weight: 700;
  margin-top: 20px;
}

.oneLocation-content-price #selected-price {
  font-size: 72px;
  font-weight: 700;
}

.oneLocation-content-price .payment-duration {
  font-size: 16px;
  font-weight: 600;
  color: var(--gray);
  margin-top: auto;
  margin-bottom: 20px;
}

.tables-status {
  font-size: 16px;
  color: #9ca3af;
  margin-top: -10px;
}

.selected-persons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px !important;
  font-weight: 700;
  background-color: #f8f8f8;
  padding: 8px;
  border-radius: 8px;
}

.selected-persons .option {
  padding: 10px 12px;
  border-radius: 8px;
  cursor: pointer;
  color: #696969;
}

.selected-persons .option:hover {
  background-color: #ffffff;
  box-shadow: 0 3px 10px 3px rgba(218, 218, 218, 1);
}

.selected-persons .selected {
  background-color: var(--dark-gray);
  color: #ffffff;
}

.selected-persons .selected:hover {
  box-shadow: 0 3px 10px 3px rgba(218, 218, 218, 1);
  background-color: var(--dark-gray);
}

.notice p {
  color: #9ca3af;
  margin-top: 10px;
  font-size: 15px !important;
}

.start-trail-btn button,
.contact-team-btn button {
  background-color: var(--main-color);
  color: #ffffff;
  padding: 10px 28px;
  border-radius: 5px;
}

.start-trail-btn button:hover,
.contact-team-btn button:hover {
  background-color: var(--main-hover-color);
  color: #ffffff;
}

.contact-sales-btn p,
.learn-more p {
  color: var(--blue);
  font-weight: 600;
  padding-top: 10px;
}

.enterprise-img img {
  width: 70%;
}

.call-us {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px !important;
  margin: 20px 0;
  cursor: pointer;
}

.call-us-icon {
  font-size: 20px !important;
}

@media screen and (max-width: 500px) {
  .pricingCard {
    width: 100%;
    padding: 15px 15px !important;
    background: transparent !important;
    border-radius: 30px;
    box-shadow: 0 2px 20px 3px rgba(218, 218, 218, 0.664);
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
  }

  .pricingCard-sliding-btn .slide {
    font-size: 14px;
  }

  .start-trail-btn button {
    width: 100%;
  }

  .oneLocation-content-price .currency {
    font-size: 30px;
  }
}
