.footer-links {
  text-align: left;
}

.footer-link {
  text-decoration: none !important;
  color: #ffffff;
}
.footer-link h2 {
  transition: all 0.1s !important;
}

.footer-link:hover h2 {
  color: #ffffff !important;
  transform: translateX(3px) !important;
}
