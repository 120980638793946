.everything-heading {
  margin: 60px 0;
}

.everything-heading h4 {
  font-size: 36px;
  font-weight: 700;
  color: var(--ligh-black);
}

.service-card-container .service-card {
  background-color: #ffffff !important;
  border: 1px solid var(--light-gray);
  box-shadow: 2px 2px 15px 5px rgba(216, 216, 216, 0.527);
  padding: 30px !important;
}

.service-card-container .service-card .service-card-text {
  font-size: 18px !important;
}

.service-card-container {
  justify-content: center;
}

.sellMore h3 {
  font-size: 36px;
  color: var(--ligh-black);
  font-weight: 700;
}
.sellMore .sellMore-text {
  font-size: 18px;
  color: var(--gray);
  padding: 10px 50px;
}

.sellMore-icons-container {
  justify-content: space-around;
  margin-top: 40px;
}

.sellMore-icon-box .sellMore-icon {
  font-size: 70px;
  padding: 10px;
  color: var(--main-color);
  background-color: var(--light-pink);
  border-radius: 999em;
  margin-bottom: 10px;
}

.sellMore-icon-box p {
  font-size: 14px;
  font-weight: 600;
  color: var(--gray);
}

@media screen and (max-width: 800px) {
  .sellMore .sellMore-text {
    padding: 10px 7px;
  }
}
