.opportunity {
  background-image: url("../../../public/images/sand-shape.svg");
  /* min-height: 100vh; */
  background-size: cover;
  width: 100%;
}

.opportunity .container-fluid {
  padding-top: 60px;
}
