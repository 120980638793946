.headingSection h4 {
  font-size: 24px;
  font-weight: 700;
  color: var(--main-color);
  text-transform: uppercase;
}

.headingSection h2 {
  font-size: 48px;
  font-weight: 600;
  color: var(--light-black);
}
.headingSection p {
  font-size: 24px;
  color: var(--light-black);
}
