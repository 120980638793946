.securityInfo > div {
  display: flex;
  align-items: center;
  text-align: left;
}

.securityInfo-img img {
  width: 100%;
}

.securityInfo-content {
  padding: 10px 30px;
}

.securityInfo-content h2 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 5px;
  color: var(--dark-gray);
}

.securityInfo-content p {
  font-size: 18px;

  color: var(--light-black);
}

@media screen and (max-width: 768px) {
  .securityInfo-content h2 {
    font-size: 35px;
  }

  .securityInfo > div {
    flex-direction: column-reverse;
  }

  .securityInfo-img {
    margin-bottom: 20px;
  }
}
