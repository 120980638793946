.leftImgCard {
  padding-top: 80px !important;
  padding-bottom: 20px !important;
}

.leftImgCard > .row {
  align-items: center !important;
}

.leftImgCard-img img {
  width: 100%;
  max-height: 530px;
  object-fit: contain;
}

.leftImgCard-content {
  text-align: left;
}

.leftImgCard-content-heading {
  font-size: 36px;
  color: var(--light-black);
  font-weight: 700;
  line-height: 45px;
  margin-bottom: 20px;
}

.leftImgCard-content-para {
  font-size: 18px;
  color: var(--gray);
}

.leftImgCard-content-icon img {
  width: 160px;
}

@media screen and (max-width: 995px) {
  .leftImgCard-content {
    padding: 5px 15px !important;
  }
}

@media screen and (max-width: 768px) {
  .leftImgCard > .row {
    flex-direction: column-reverse;
  }

  .leftImgCard-content {
    text-align: center;
    padding: 5px 15px !important;
  }

  .leftImgCard-img {
    margin-top: 35px;
  }
}
