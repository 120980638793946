.featuresOption {
  display: flex;
  padding: 5px 6px;
  text-align: left;
  width: 260px !important;
  border-radius: 7px;
  text-decoration: none !important;
}

.featuresOption:hover {
  background-color: var(--options-hover);
}

.featuresOption-icon img {
  background-color: var(--icon-bg);
  width: 34px !important;
  height: 34px;
  padding: 8px 8px;
  border-radius: 999em;
}

.featuresOption-content h5 {
  font-size: 16px;
  margin: 0;
  margin-left: 8px;
  color: var(--light-black);
}

.featuresOption-content P {
  font-size: 12px;
  color: gray;
  margin-left: 8px;
  margin-bottom: 0;
}

.featuresOption-content-newTag {
  font-size: 11px;
  color: #ffffff;
  background-color: #7cc576;
  margin-left: 5px;
  padding: 1px 8px;
  border-radius: 10px;
}

@media screen and (max-width: 990px) {
  .featuresOption {
    min-width: 100% !important;
    align-items: center;
  }

  .featuresOption-content P {
    display: none;
  }
}
