.chat-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.chat-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--main-color);
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.chat-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  color: #ffffff;
  font-weight: 700;
}

.chat-box {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 330px;
  height: 550px !important;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease-in-out;
  border-radius: 10px;
}

.chat-box-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  font-weight: 600;
}
.chat-box-header .MuiSvgIcon-root {
  cursor: pointer;
}

.minimize-icon {
  padding-bottom: 8px;
  font-size: 30px !important;
}
.chat-box p {
  margin-top: 10px;
}

.chat-box-body {
  background-color: var(--very-light-gray);
  height: 480px !important;
  padding: 30px 15px 10px;
  overflow-y: scroll;
}
