.plateforms {
  width: 95% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
}

.plateforms > .row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.plateforms-card {
  margin: 10px 20px;
  transition: 0.8s !important;
  cursor: pointer;
}
.plateforms-card:hover {
  transform: scale(1.1);
}

.plateforms img {
  width: 100px;
}
