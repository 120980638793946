@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@500;600;700&family=Quicksand:wght@500;600;700&family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;0,900;1,500&family=Space+Grotesk:wght@500&display=swap");

* {
  padding: 0;
  margin: 0;
}

:root {
  --main-color: #e60e89;
  --main-hover-color: #ac3a38;
  --light-black: #1f2937;
  --options-hover: #f9fafb;
  --icon-bg: #e0bdd1ee;
  --carousel-bg: #456367;
  --gray: #4b5563;
  --light-gray: #cecece;
  --very-light-gray: #f8f8f8;
  --dark-gray: #333333;
  --light-voilet: #f3ded3;
  --light-pink: #f9f5ed;
  --pale-green: #d9ebd8;
  --blue: #3b82f6;
  --blue-hover: #2b70df;
  --pale-blue: #e5e7eb;
  --green: #7cc576;
  --light-pink: #facaca;
  --revyfy-dark: #1b1d2a;
}

body {
  margin: 0;
  font-family: "Montserrat", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  box-sizing: border-box;
}

.jump-hover,
.move-right-hover {
  transition: all 0.2s !important;
}

.jump-hover:hover {
  transform: translateY(-2px);
}

.move-right-hover:hover {
  transform: translateX(2px) !important;
}

/* USed In All Business Types Pages */
.get-paid {
  background-image: url(../public/images/business-pink-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 200px !important;
  padding-bottom: 150px !important;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
