.setupCard-container > .row {
  justify-content: space-evenly;
}

.setupCard {
  background-color: #ffffff;
  box-shadow: 0 2px 20px 5px rgba(216, 216, 216, 0.589);
  border-radius: 15px;
  padding: 30px;
}

.setupCard .cardWithImage-content {
  text-align: center !important;
}

.setupCard .cardWithImage-content h3 {
  font-size: 20px !important;
}

.setupCard .cardWithImage-content p {
  font-size: 16px !important;
}
