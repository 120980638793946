.addOnOption {
  text-align: left;
}

.addOnOption-title {
  font-size: 20px;
  color: var(--dark-gray);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.addOnOption-desc {
  font-size: 17px;
  color: gray;
}
