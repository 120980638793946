.carousel-inner {
  justify-content: center;
}
.carousel-indicators {
  margin-top: 10px !important;
  margin-bottom: -50px;
}

.carousel-indicators button {
  background-color: black !important;
  border-radius: 999em !important;
  width: 5px !important;
  height: 5px !important;
  padding: 3px !important;
  margin: 0 5px !important;
}

.carousel-control-prev {
  position: absolute;
  left: 0px;
}

.carousel-control-next {
  position: absolute;
  right: 0px;
}

.carousel-control-prev-icon {
  background-image: url("../../../public/images/left.svg") !important;
}
.carousel-control-next-icon {
  background-image: url("../../../public/images/right.svg") !important;
}

@media screen and (max-width: 990px) {
  .carousel-control-prev {
    position: absolute;
    left: 0px;
  }

  .carousel-control-next {
    position: absolute;
    right: 0px;
  }
}
