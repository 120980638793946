.addOnFeatures-heading h2 {
  font-size: 35px;
  font-weight: 700;
  color: var(--dark-gray);
}

.addOnFeatures-heading p {
  font-size: 24px;
  color: gray;
}

.addOnFeatures-list {
  padding-left: 7px;
  padding-right: 7px;
  overflow: hidden;
  max-height: 0px;
  transition: max-height !important;
}

.show-features {
  max-height: 100%;
  transition: 0.3s !important;
}

.addOnFeatures-btn-icon {
  transition: transform 0.3s !important;
}

.addOnFeatures-btn-icon-rotate {
  transform: rotate(180deg);
  transition: all 0.3s !important;
}

.addOnFeatures-btn {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;
}

.addOnFeatures-btn button {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  font-size: 17px;
  font-weight: 700;
  color: var(--light-black);
  transition: all 0.2s;
}

.addOnFeatures-btn-icon {
  width: 30px !important;
  height: 30px !important;
  margin-left: 5px;
}

.addOnFeatures-btn button:hover {
  color: var(--blue);
}
