.card {
  border: none;
  cursor: pointer;
  position: relative;
}

.card .card-img-overlay {
  padding: 0;
  height: 100%;
}

.card .card-img {
  height: 100% !important;
}

.card:hover .card-img-overlay {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.card:hover {
  box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.2);
}

.businessCard-dropDown {
  position: absolute;
  width: 415px;
  text-align: left;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 5px;
  box-shadow: 0 3px 15px 3px rgba(209, 209, 209, 0.671);
  background-color: #ffffff;
  z-index: 100 !important;
}

.businessCard-dropDown-option {
  padding: 4px 0;
  cursor: pointer;
  font-weight: 600;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.businessCard-dropDown-option-option {
  padding: 8px 16px;
}

.businessCard-dropDown-option-option:hover {
  background-color: #f3f4f6;
}

.businessCard-dropDown-option-link {
  text-decoration: none !important;
  color: var(--light-black) !important;
}

.businessCard-dropDown-option-link:hover {
  color: var(--light-black) !important;
}

.businessCard-dropDown-option-link img {
  margin-right: 10px;
}

@media screen and (max-width: 1400px) {
  .businessCard-dropDown {
    width: 355px;
  }
}
@media screen and (max-width: 1200px) {
  .businessCard-dropDown {
    width: 300px;
  }
}
@media screen and (max-width: 990px) {
  .businessCard-dropDown {
    width: 220px;
  }
}
@media screen and (max-width: 768px) {
  .businessCard-dropDown {
    width: 515px;
  }
}
@media screen and (max-width: 568px) {
  .businessCard-dropDown {
    width: 95%;
  }
}
