.features-menu-card {
  width: 800px;
  height: 100%;
  left: -360px;
  top: 23px;
}

.features-options > div {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 990px) {
  .features-menu-card {
    width: 100%;
    height: 100vh;
    left: 0;
    top: -85px !important;
  }

  .features-options > div {
    display: block;
  }
}
