.explore-more {
  color: var(--light-black);
  padding: 160px 0 80px;
  background-color: var(--very-light-gray);
}

.explore-more-heading h3 {
  font-size: 16px;
  margin: 16px 0;
}

.explore-more-heading h2 {
  font-size: 36px;
  margin-bottom: 32px;
}

.explore-more-cards {
  justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
  .explore-more-cards {
    padding: 48px 15px !important;
  }
}
