.chatForm {
  background-color: #ffffff;
  text-align: left;
  border-radius: 10px;
  padding-bottom: 10px;
}

.chatForm .logo {
  text-align: center;
}

.chatForm .logo .MuiSvgIcon-root {
  background-color: var(--main-color);
  color: #ffffff;
  font-size: 40px;
  border-radius: 999em;
  padding: 5px;
  margin-top: -15px;
}

.chatForm .form-group label {
  font-size: 14px;
  font-weight: 600;
}

.chatForm .form-group input,
.chatForm .form-group select {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 5px;
  border-bottom: 1px solid rgb(212, 212, 212);
  outline: none;
  box-shadow: none;
  background-color: var(--very-light-gray);
}

.chatForm .form-group input:focus,
.chatForm .form-group select:focus {
  outline: none;
  background: transparent;
}

.form-group-check label {
  display: flex;
  align-items: center;
  justify-content: left;
}

.form-group-check label input {
  display: inline !important;
  width: 30px !important;
  margin: 0 !important;
}

.chatForm button {
  margin-top: 15px;
}
