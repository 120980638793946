.trail-container {
  margin-top: 200px !important;
}

.trail-body {
  padding: 80px 50px !important;
  background-color: var(--main-color);
  position: relative;
  text-align: left;
  color: var(--very-light-gray);
  border-radius: 30px;
}

.trail-body h2 {
  font-size: 36px !important;
  font-weight: 700;
}

.trail-body p {
  font-size: 24px;
}

.trail-body-btn {
  margin-top: 30px !important;
}

.trail-body-btn button {
  padding: 15px 20px !important;
}

.trail-body-img img {
  width: 450px;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 990px) {
  .trail-body-img img {
    width: 33vw;
  }
}

@media screen and (max-width: 768px) {
  .trail-body-img {
    display: none !important;
  }

  .trail-container {
    margin-top: 100px !important;
  }

  .trail-body {
    padding: 50px 30px !important;
    text-align: center !important;
    margin-left: 5px;
    margin-right: 5px;
  }
}
