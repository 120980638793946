.featuresHero {
  background-color: var(--very-light-gray);
  padding: 60px 0;
}

.featuresHero .container-fluid {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.featuresHero .container-fluid > div {
  align-items: center;
}

.featuresHero-main-content {
  color: var(--light-black);
  text-align: left;
}

.featuresHero-main-content h1 {
  font-size: 60px;
  font-weight: 700;
}

.featuresHero-main-content p {
  font-size: 24px;
  color: var(--gray);
  margin-top: 15px;
}

.featuresHero-main-btns {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.featuresHero-main-btns button {
  font-weight: 600;
}

.featuresHero-main-trailBtn button {
  padding: 15px 18px !important;
  border-radius: 5px;
  margin-right: 15px;
}

.featuresHero-main-contactBtn button {
  color: var(--blue);
  margin-left: 10px;
}

.featuresHero-main-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.featuresHero-main-img img {
  width: 95%;
  border-radius: 15px;
}

@media screen and (max-width: 990px) {
  .featuresHero-main-content {
    text-align: center;
  }

  .featuresHero-main-content h1 {
    font-size: 53px;
  }
}

@media screen and (max-width: 768px) {
  .featuresHero {
    padding: 20px 0;
  }
  .featuresHero .container-fluid {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .featuresHero-main-content h1 {
    font-size: 35px !important;
  }

  .featuresHero-main-content p {
    font-size: 20px !important;
  }

  .featuresHero-main-btns {
    display: block;
  }

  .featuresHero-main-btns button {
    width: 100%;
  }

  .featuresHero-main-contactBtn button {
    margin-top: 15px;
  }

  .featuresHero-main-img {
    margin-top: 50px;
  }
}
