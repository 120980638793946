.signupForm {
  background-color: #f5f5f5;
  height: 100%;
  width: 100% !important;
}

.signupForm > div > div {
  display: flex !important;
  align-items: center !important;
  justify-content: space-evenly;
}

.signupForm-img img {
  width: 26vw;
}

.signupForm-logo {
  color: var(--main-color) !important;
  font-weight: 700;
  font-size: 25px !important;
  margin-bottom: 15px;
  border-bottom: 1px solid rgb(212, 212, 212);
  padding: 10px;
}

.signupForm-form {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 20px 3px rgba(218, 218, 218, 0.664);
  height: 100%;
}

.signupForm .loginButtons {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.signupForm .signupButtons p {
  margin: 0;
}

.signupForm .signupButtons .signupButtons-link {
  text-decoration: none;
}

.signupForm .or-divider {
  display: flex;
  align-items: center;
  padding: 0 25px;
}

.signupForm .or-text {
  padding: 5px 10px;
  font-weight: 600;
}

.signupForm.or-divider .line {
  height: 1px;
  background-color: rgb(212, 212, 212);
  width: 100%;
}

.signupForm {
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.signupFormform {
  margin-top: 10px;
  padding: 2px 25px;
}

.inputGroup-inputs {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 7px;
  margin-bottom: 15px;
}

.inputGroup-inputs input {
  padding: 8px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.inputGroup-inputs input:focus {
  border: 1px solid var(--blue);
  outline: none;
}

.signupForm.inputGroup {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border: 1px solid #ccc;
}

.signupForm.inputGroup input {
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
  background-color: transparent;
}

.signupForm.inputIcon {
  color: #888;
  font-weight: 300;
  margin-left: 10px;
}

.inputGroup-phone {
  margin-bottom: 15px;
}

.inputGroup-phone-input {
  display: flex;
  align-items: center;
  height: 100%;
}

.PhoneInputCountry {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  border: 1px solid #ccc;
  padding: 8px;
}

.PhoneInputCountry select {
  border: none;
  width: 80px;
}
.PhoneInputCountry select:focus {
  outline: none;
}

.PhoneInputInput {
  padding: 8px;
  width: 100%;
}

.PhoneInputCountryIcon--border img {
  height: 25px !important;
}

.inputGroup-gender select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 15px;
  cursor: pointer;
}

.inputGroup-gender select:focus {
  border: 1px solid var(--blue);
  outline: none;
}

.signupFormbutton[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: var(--main-color);
  color: #fff;
  border: none;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
}

.select-button {
  display: inline-block;
  padding: 8px 20px;
  border: 1px solid #ccc;

  cursor: pointer;
}

.selected {
  border: 1px solid var(--blue);
}

.inputGroup-appointment {
  text-align: left;
  margin-bottom: 15px;
}
.inputGroup-appointment p {
  margin: 0;
  font-weight: 600;
}

.inputGroup-appointment-btns {
  display: flex;
}

.terms-agr {
  font-size: 12px;
  padding: 10px 0 16px;
}

.terms-agr-link {
  text-decoration: none;
}

.signupFormbutton[type="submit"]:hover {
  background-color: var(--main-hover-color);
}

.signupForm.signup {
  font-weight: 600;
  padding: 10px 0 15px;
}

.signup span {
  margin-right: 10px;
  font-size: 12px !important;
}

.signupForm .signup .signin-link {
  text-decoration: none;
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .signupForm .signupForm-img {
    display: none;
  }
}
