.service-card {
  min-width: 224px;
  min-height: 160px;
  padding: 20px;
  margin: 0 5px;
  border: 1px solid #f5ebe0;
  border-radius: 10px;
  color: var(--light-black);
}

.service-card .service-card-body span {
  padding: 10px 13px;
  background-color: #f5ebe0;
  border-radius: 140px;
}

.service-card-img img {
  width: 40px;
  height: 40px;
  border-radius: 9em;
  background-color: var(--icon-bg);
  vertical-align: middle;
  padding: 10px;
}

.service-card-body h5 {
  font-size: 20px;
  font-weight: 700;
}

.service-card-text {
  font-size: 16px !important;
  margin-top: 15px !important;
  color: var(--gray);
}

.service-card:hover {
  background-color: var(--services-hover);
  cursor: pointer;
}
