.pricingBenefits {
  margin-top: 20px;
  background-color: var(--very-light-gray);
}

.pricingBenefits-heading h2 {
  font-weight: 700;
}

@media screen and (max-width: 749px) {
  .pricingBenefits {
    padding: 40px 5px !important;
  }
  .pricingBenefits-heading h2 {
    font-size: 32px;
  }
}
