.accordionItem {
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  box-shadow: none !important;
  border-top: 1px solid var(--very-light-gray) !important;
  margin-bottom: 1px !important;
  margin-top: 0 !important;
  background-color: transparent !important;
  text-align: left;
}

.accordionItem-heading {
  color: var(--dark-gray) !important;
  padding: 9px 0 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  text-align: left;
}

.accordionItem-details {
  padding-top: 0 !important;
  text-align: left;
}

.accordionItem-content {
  font-size: 16px !important;
  color: var(--gray) !important;
  text-align: left !important;
}

@media screen and (max-width: 705px) {
  .accordionItem-heading {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
}
