.payrollHeading h2 {
  font-size: 35px;
  font-weight: 700;
  color: var(--light-black);
}

.payrollHeading p {
  font-size: 18px !important;
  color: var(--gray) !important;
}
