.features {
  overflow-x: hidden;
  width: 100%;
}

.milestone {
  display: inline-flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 20px;
}

.features-heading {
  font-size: 36px;
  color: var(--light-black);
  font-weight: bold;
}

.milestone::-webkit-scrollbar {
  height: 10px;
  opacity: 0 !important;
  transition: 0.3s;
}

.milestone:hover::-webkit-scrollbar {
  opacity: 1;
}

.milestone::-webkit-scrollbar-track {
  background-color: transparent;
}

.milestone::-webkit-scrollbar-thumb {
  width: 20px !important;
  background-color: gray;
  border-radius: 100px;
}

.features-scroll {
  width: 100%;
}

.features-scroll-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  padding-top: 15px;
}

.features-scroll-icon img {
  width: 50px;
  margin-left: 5px;
}
