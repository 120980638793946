.fullWidthCard {
  text-align: left;
  color: var(--light-black);
  background-color: #ffff;
  border-radius: 25px;
  width: 100%;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.fullWidthCard > div > div {
  align-items: center;
}

.fullWidthCard-img {
  height: 100%;
  padding: 0 !important;
}

.fullWidthCard-img img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 25px 0 0 25px;
}

.fullWidthCard-content-smallHeading {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.fullWidthCard-content-mainHeading {
  font-size: 36px;
  font-weight: 700;
  margin: 20px 0 15px;
}

.fullWidthCard-content-desc {
  font-size: 16px;
  color: var(--gray);
}

.fullWidthCard-content-list {
  margin: 20px 0 15px;
}

.fullWidthCard-content-list-item {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 0;
  color: var(--gray);
}

.fullWidthCard-content-list-item .list-item-icon {
  color: var(--green);
}

.fullWidthCard-content-learnMore button {
  color: var(--blue);
  font-weight: 600;
}

@media screen and (max-width: 1050px) {
  .fullWidthCard {
    display: block;
  }
  .fullWidthCard-img {
    width: 100% !important;
  }
  .fullWidthCard-content {
    display: block;
    width: 100%;
    padding: 20px 35px;
  }

  .fullWidthCard-img img {
    border-radius: 25px 25px 0 0;
  }
}
