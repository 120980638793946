.proHeader {
  padding: 128px 0 30px;
  /* margin: -40px 0 0 0; */
  background-image: url("../images/red-wave.svg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  width: 100%;
  overflow-x: hidden;
}

.proHeader-heading {
  font-size: 60px;
  color: #ffffff;
  text-align: left;
  font-weight: 700;
}

.proHeader-logo-img {
  text-align: left;
}

.header-btns {
  display: flex;
  align-items: center;
}

.trail-btn {
  background-color: #f8f8f8;
  color: #333333;
  padding: 15px 20px !important;
  margin-right: 16px;
  font-size: 16px;
  font-weight: 600;
}
.trail-btn:hover {
  background-color: #f8f8f8;
}

.contact-sales-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff !important;
  background: none;
  outline: none;
  border: none;
  font-weight: 600;
}

.contact-sales-btn img {
  width: 12px;
  margin-left: 5px;
}

.proHeader-logo-img img {
  width: 400px;
}

.proHeader-images {
  position: relative;
}
.proHeader-images img {
  width: 100%;
}

.proHeader-images .calander-img {
  height: 550px;
  width: 1192px;
  object-fit: contain;
  overflow-x: hidden;
}

.proHeader-images .mobile-img {
  width: 400px;
  bottom: -80px;
  left: -40px;
  position: absolute;
  z-index: 99;
}

.appointment-container {
  margin-top: 80px;
}

.appointment-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 20px;
  background-color: #333333;
  border-radius: 8px;
  color: #ffffff;
  background-image: url("../images/bar-elipse-shapes.svg");
  background-repeat: no-repeat;
  background-position: left;
  background-position: bottom left;
  box-shadow: 0 0px 18px -3px rgba(0, 0, 0, 0.2),
    0 0px 14px -4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.appointment-box p {
  margin: 0 10px 0;
}

.appointment-btn {
  background: none;
  border: none;
  color: #ffffff;
}

@media screen and (max-width: 990px) {
  .proHeader-body {
    display: flex;
    flex-direction: column-reverse;
  }

  .proHeader {
    padding: 30px 0;
  }

  .proHeader-body > div {
    width: 100% !important;
  }

  .proHeader-content {
    margin: 50px 0;
    padding: 20px 10px;
  }

  .proHeader-heading {
    font-size: 9vw;
    color: #ffffff;
    text-align: left;
    font-weight: 700;
  }
  .proHeader-logo-img img {
    width: 90%;
  }

  .header-btns,
  .contact-sales-btn-div {
    text-align: center;
  }

  .header-btns .col-sm-12 {
    margin: 10px 0 !important;
  }

  .header-btns button {
    width: 100%;
  }

  .contact-sales-btn {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .proHeader-images .calander-img {
    max-width: 80%;
    min-width: 560px;
    margin-left: 100px;
    height: 355px;
    position: relative;
  }

  .proHeader-images .mobile-img {
    position: absolute;
    width: 300px;
    object-fit: contain;
    top: 0;
    left: 0px;
  }

  .appointment-box {
    display: block;
  }
}
