.contactUsCard {
  background-color: #ffffff !important;
  border-radius: 7px;
  box-shadow: 0 2px 20px 3px rgba(218, 218, 218, 0.664);
}

.contactUsCard-head-icon img {
  width: 50px;
  background-color: var(--light-voilet);
  padding: 5px;
  border-radius: 999em;
}

.contactUsCard-head-title {
  font-weight: 400;
  padding: 10px 0;
}

.contactUsCard-head-time {
  color: var(--gray);
}

.list-group-item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
  font-weight: 600;
  padding: 15px 0;
}

.list-group-item:hover {
  background-color: var(--pale-blue);
  color: var(--blue);
}

.list-group-icon img {
  width: 26px;
  padding-right: 10px;
}
