.contactSalesForm-form {
  background-color: #ffffff;
  border-radius: 10px;
  width: 900px;
  box-shadow: 0 1px 15px 7px rgba(236, 236, 236, 0.767);
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.contactSalesForm h1 {
  font-size: 36px;
  color: var(--light-black);
  font-weight: 700;
}

.contactSalesForm h4 {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 20px !important;
  color: var(--light-black);
  font-weight: 600 !important;
}

.contactSalesForm-heading-img {
  width: 30px;
}

.contactSalesForm .input-box {
  text-align: left;
  color: var(--light-black);
  margin-top: 10px !important;
  padding: 8px !important;
}

.contactSalesForm .input-box-label {
  font-weight: 500 !important;
}

.astarisk,
.error-msg {
  color: #f2545b;
  font-size: 14px;
}

.error-msg {
  display: none !important;
}

.contactSalesForm .input-box input,
.contactSalesForm .input-box select,
.contactSalesForm .input-box textarea {
  padding: 8px;
  border: 1px solid var(--light-gray);
  border-radius: 5px;
  width: 100%;
  background-color: #f5f8fa;
}

.contactSalesForm .input-box input::placeholder {
  color: rgb(160, 160, 160);
}

.contactSalesForm .input-box input:focus,
.contactSalesForm .input-box select:focus,
.contactSalesForm .input-box textarea:focus {
  border: 1px solid rgb(96, 96, 255) !important;
  outline: 1px;
}

.check-box-div {
  display: flex;
  align-items: center;
}

.check-box-div input[type="checkbox"] {
  width: auto;
  margin-right: 5px;
}

.contactSalesForm .send-btn {
  margin-top: 10px;
  text-align: left;
  margin-bottom: 10px;
}

.contactSalesForm .send-btn button {
  padding: 10px 5px;
  border-radius: 5px;
  background-color: var(--main-color);
  color: #ffffff;
  border: none;
  font-weight: 600;
  width: 15%;
}

@media screen and (max-width: 768px) {
  .contactSalesForm h1 {
    font-size: 33px !important;
  }

  .contactSalesForm h4 {
    font-size: 16px;
  }

  .contactSalesForm-form {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .contactSalesForm .send-btn button {
    width: 100%;
  }
}
