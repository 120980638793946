.contactUs {
  padding: 0;
}

.contactUs-top-section {
  display: flex;
  padding: 150px 0;
  height: 460px;
  position: relative;
  overflow: hidden;
  z-index: 3 !important;
  background: linear-gradient(#ffffff, #f0d2c0);
}

.contactUs-heading {
  text-align: center;
  width: 100%;
  margin-top: -50px;
  z-index: 100;
}

.contactUs-heading h2 {
  font-size: 56px;
  font-weight: 700;
}

.contactUs-heading p {
  font-size: 20px;
  color: var(--gray);
}

.contactUs-top-section .triangle {
  width: 450px;
  position: absolute;
  z-index: 4 !important;
}

.contactUs-top-section .triangle img {
  width: 100%;
}

.contactUs-top-section .left-triangle {
  transform: rotate(180deg);
  left: -10px;
}
.contactUs-top-section .right-triangle {
  right: -10px;
}

.contactUs-bottom-section {
  height: 100%;
  padding: 50px 0;
}

.contactUs-bottom-section {
  width: 100%;
  min-height: 570px;
  position: relative;
  margin-top: -250px;
  z-index: 100 !important;
}

.contactUs-bottom-section > div {
  justify-content: center;
}
