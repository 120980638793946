.functionalityFeaturesOption {
  display: flex;
}

.functionalityFeaturesOption-icon img {
  width: 22px !important;
  height: 22px !important;
  margin-right: 15px;
}

.functionalityFeaturesOption-text {
  font-size: 18px;
  color: var(--light-black);
  text-align: left;
}
