.leftToRightFeatureContainer > div > div {
  align-items: center;
}

.leftToRightFeatureContainer {
  text-align: left;
  color: var(--light-black);
}

.leftToRightFeatureContainer-smallHeading {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.leftToRightFeatureContainer-mainHeading {
  font-size: 36px;
  font-weight: 700;
  margin: 20px 0 30px;
}

.leftToRightFeatureContainer-desc {
  font-size: 18px;
  color: var(--gray);
}

.leftToRightFeatureContainer-list {
  margin: 20px 0 15px;
}

.leftToRightFeatureContainer-list-item {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 0;
  color: var(--gray);
}

.leftToRightFeatureContainer-list-item .list-item-icon {
  color: var(--green);
}

.leftToRightFeatureContainer-learnMore button {
  color: var(--blue);
  font-weight: 600;
}

.leftToRightFeatureContainer-img img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .leftToRightFeatureContainer {
    text-align: center;
  }
}
