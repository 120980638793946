.businessOption {
  display: flex;
  align-items: center;
  padding: 5px 6px;
  text-align: left;
  width: 100%;
  border-radius: 7px;
  text-decoration: none !important;
}

.businessOption:hover {
  background-color: var(--options-hover);
}

.businessOption-icon img {
  background-color: var(--icon-bg);
  padding: 10px 9px;
  border-radius: 999em;
}

.businessOption-content h5 {
  font-size: 16px;
  margin: 0;
  margin-left: 8px;
  color: var(--light-black);
}

.businessOption-content P {
  font-size: 12px;
  color: var(--light-black);
  margin-left: 8px;
  margin-bottom: 0;
}
