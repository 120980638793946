.business-menu-card {
  width: 600px;
  left: -230px;
  top: 23px;
}

@media screen and (max-width: 990px) {
  .business-menu-card {
    width: 100%;
    height: 600px;
    left: 0;
    top: 0;
  }
}
