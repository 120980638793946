.LoginForm {
  background-color: #f5f5f5;
  height: 100%;
}

.LoginForm > div > div {
  justify-content: space-evenly;
}

.loginForm-img img {
  width: 27vw;
}

.loginForm-logo {
  color: var(--main-color) !important;
  font-weight: 700;
  font-size: 25px !important;
  margin-bottom: 15px;
  border-bottom: 1px solid rgb(212, 212, 212);
  padding: 10px;
}

.loginForm-form {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 20px 3px rgba(218, 218, 218, 0.664);
  height: 100%;
}

.loginButtons {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.loginButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}

.loginButton.facebook {
  background-color: #3b5998;
}

.loginButton.google {
  background-color: #dd4b39;
}

.loginButton.apple {
  background-color: #000;
}

.loginButton .icon {
  margin-right: 8px;
}

.or-divider {
  display: flex;
  align-items: center;
  padding: 0 25px;
}

.or-text {
  padding: 5px 10px;
  font-weight: 600;
}

.or-divider .line {
  height: 1px;
  background-color: rgb(212, 212, 212);
  width: 100%;
}

.loginForm {
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

form {
  margin-top: 10px;
  padding: 2px 25px;
}

.inputGroup {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border: 1px solid #ccc;
}

.inputGroup input {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  background-color: transparent;
}

.inputIcon {
  color: #888;
  font-weight: 300;
  margin-left: 10px;
}

.checkboxGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.checkboxGroup label {
  display: flex;
  align-items: center;
}

.checkboxGroup input[type="checkbox"] {
  margin-right: 5px;
}

.forgot-link {
  text-decoration: none;
  color: var(--blue);
  font-weight: 600;
}

#rememberMe {
  transform: scale(1.5);
  margin-right: 10px;
}

#rememberMe:checked {
  background-color: gray;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: var(--main-color);
  color: #fff;
  border: none;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: var(--main-hover-color);
}

.signup {
  font-weight: 600;
  padding: 10px 0 15px;
}

.signup span {
  margin-right: 10px;
}

.signup .signup-link {
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .loginForm-img {
    display: none;
  }
}
