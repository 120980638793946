.businessTypesHero {
  background-image: url(/public/images/hero-wave2.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.businessTypesHero-main-content {
  color: var(--light-black);
  text-align: left;
}

.businessTypesHero-main-content h3 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 20px;
}

.businessTypesHero-main-content h1 {
  font-size: 60px;
  font-weight: 700;
}

.businessTypesHero-main-content p {
  font-size: 24px;
  color: var(--gray);
  margin-top: 15px;
}

.businessTypesHero-main-btns {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.businessTypesHero-main-btns button {
  font-weight: 600;
}

.businessTypesHero-main-trailBtn button {
  padding: 15px 18px !important;
  border-radius: 5px;
  margin-right: 15px;
}

.businessTypesHero-main-contactBtn button {
  color: var(--blue);
  margin-left: 10px;
}

.businessTypesHero-main-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.businessTypesHero-main-img img {
  width: 95%;
  border-radius: 25px;
}

@media screen and (max-width: 990px) {
  .businessTypesHero-main-content {
    text-align: center;
  }

  .businessTypesHero-main-content h1 {
    font-size: 47px;
  }
}

@media screen and (max-width: 768px) {
  .businessTypesHero-main-btns {
    display: block;
  }
  .businessTypesHero-main-btns button {
    width: 100%;
  }

  .businessTypesHero-main-img {
    margin-top: 30px;
  }
}
