.accordion-container {
  display: inline-block;
  justify-content: center;
  height: auto !important;
}

.accordion-heading h2 {
  font-size: 36px;
  color: var(--dark-gray);
  font-weight: 700;
}
