.pricingSection {
  background-image: url("../../../public/images/wave.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.pricingSection-main-heading h1 {
  font-size: 72px;
  font-weight: 700;
}

@media screen and (max-width: 500px) {
  .pricingSection-main-heading h1 {
    font-size: 14vw;
  }
}
