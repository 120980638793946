.companyInfo {
  text-align: left;
  margin-top: 50px;
  margin-bottom: 50px;
}

.companyInfo-heading {
  font-size: 30px;
  font-weight: 700;
}

.companyInfo-details p {
  color: gray;
}

.contact-link {
  text-decoration: none !important;
}
.contact-link p {
  color: var(--blue);
  font-weight: 600;
}

.divider {
  background-color: var(--light-gray);
  height: 1px;
}
