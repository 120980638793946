.rightToLeftFeatureContainer > div > div {
  align-items: center;
}

.rightToLeftFeatureContainer {
  text-align: left;
  color: var(--light-black);
}

.rightToLeftFeatureContainer-smallHeading {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.rightToLeftFeatureContainer-mainHeading {
  font-size: 36px;
  font-weight: 700;
  margin: 20px 0 30px;
}

.rightToLeftFeatureContainer-desc {
  font-size: 18px;
  color: var(--gray);
}

.rightToLeftFeatureContainer-list {
  margin: 20px 0 15px;
}

.rightToLeftFeatureContainer-list-item {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 0;
  color: var(--gray);
}

.rightToLeftFeatureContainer-list-item .list-item-icon {
  color: var(--green);
}

.rightToLeftFeatureContainer-learnMore button {
  color: var(--blue);
  font-weight: 600;
}

.rightToLeftFeatureContainer-img img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .rightToLeftFeatureContainer {
    text-align: center;
  }

  .rightToLeftFeatureContainer > div > div {
    flex-direction: column-reverse !important;
  }
}
