.country-menu-card {
  width: 220px;
  height: 100%;
  left: -80px !important;
  top: 23px !important;
}

@media screen and (max-width: 990px) {
  .country-menu-card {
    position: absolute;
    width: 100% !important;
    height: 100vh !important;
    left: 0 !important;
    top: -350px !important;
    box-shadow: none !important;
  }
}
