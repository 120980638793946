.cardWithImage img {
  width: 100% !important;
  border-radius: 15px;
}

.cardWithImage-content {
  text-align: left;
}

.cardWithImage-content h3 {
  font-size: 24px;
  font-weight: 700 !important;
  margin: 12px 0;
}

.cardWithImage-content p {
  font-size: 18px;
  color: var(--gray);
  font-size: 600 !important;
}

.cardWithImage-list {
  margin: 20px 0 15px;
}

.cardWithImage-list-item {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 0;
  color: var(--gray);
}

.cardWithImage-list-item .list-item-icon {
  color: var(--green);
}
