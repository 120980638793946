.carousal__text {
  background-color: var(--carousel-bg);
  min-height: 40vh;
  border-radius: 20px;
}

.carousel-item-img {
  margin-top: -20px;
  margin-bottom: -20px;
  overflow-y: visible;
}

.carousel-item-img img {
  height: 430px;
  width: 100%;
  object-fit: cover;
}

.review-container {
  text-align: left !important;
}

.review-text {
  font-size: 24px;
  color: #ffffff;
  text-align: justify;
}

.review-writer p {
  color: #ffffff;
  margin: 0;
  font-size: 16px;
}

@media screen and (max-width: 990px) {
  .carousal__text {
    width: 100%;
  }
  .carousel-item {
    justify-content: center;
  }
  .carousel-item-content {
    padding: 0 15px !important;
  }

  .review-container {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .carousel-item-img {
    margin-top: 10px;
    margin-bottom: -20px;
    overflow-y: visible;
  }

  .carousel-item-img img {
    height: 270px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .review-container h2 {
    font-size: 4.5vw;
  }
}
