.Payments > .container > .row {
  align-items: center;
}

.payments-img {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 660px) {
  .payments-content {
    padding: 20px 5px !important;
    margin-top: 30px !important;
  }
}
