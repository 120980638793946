.contactDemoComponent {
  background-image: url(/public/images/hero-wave2.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.contactDemoComponent-form {
  background-color: #ffffff;
  border-radius: 10px;
  width: 672px;
  box-shadow: 0 1px 15px 7px rgba(236, 236, 236, 0.767);
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.contactDemoComponent-form h1 {
  font-size: 48px;
  color: var(--light-black);
  font-weight: 700;
}

.contactDemoComponent-form h4 {
  font-size: 24px;
  color: var(--gray);
  font-weight: 400 !important;
}

.contactDemoComponent-smallheading {
  font-size: 16px;
  margin-top: 30px;
  color: var(--gray);
  font-weight: 600;
}

.radio {
  justify-content: space-between;
}

.radio-box {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 20px;
  border: 1px solid var(--light-gray);
  border-radius: 25px;
  flex-basis: 47%;
}

.radio-box-label {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}

.radio-box input[type="radio"] {
  margin-right: 5px;
  transform: scale(1.2);
}

.radio-box-label-icon {
  font-size: 40px !important;
}

.radio-box-label span {
  width: 100%;
}

.input-box {
  text-align: left;
  color: var(--light-black);
  margin-top: 10px !important;
  padding: 8px !important;
}

.input-box-label {
  font-weight: 600 !important;
}

.input-box input,
.input-box select,
.input-box textarea {
  padding: 12px 8px;
  border: 1px solid var(--light-gray);
  border-radius: 12px;
  width: 100%;
}

.input-box input:focus,
.input-box select:focus,
.input-box textarea:focus {
  border: 1px solid blue !important;
  outline: 1px;
}

.send-btn {
  margin-top: 10px;
}

.send-btn button {
  padding: 15px 20px;
  border-radius: 5px;
  background-color: var(--main-color);
  color: #ffffff;
  border: none;
  font-weight: 600;
  width: 50%;
}

@media screen and (max-width: 768px) {
  .contactDemoComponent-form h1 {
    font-size: 40px;
  }

  .contactDemoComponent-form h4 {
    font-size: 20px;
  }

  .contactDemoComponent-form {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .radio-box {
    flex-basis: 100%;
    justify-content: center;
  }

  .send-btn button {
    width: 100%;
  }
}
