.logo-menu-card {
  width: 220px;
  height: 100%;
  left: 10px !important;
  top: 28px !important;
}

.logo-menu-card .tip {
  left: -70px;
}

@media screen and (max-width: 990px) {
  .logo-menu-card {
    width: 200px;
    height: 200px !important;
    left: 30px !important;
    top: 50px !important;
    z-index: 10000;
    box-shadow: 5px 2px 15px 10px rgba(0, 0, 0, 0.2);
  }

  .logo-menu-card .tip {
    display: block;
    left: -70px;
  }
}
