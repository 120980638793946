.textCardWithBtn-link {
  text-decoration: none !important;
  color: var(--light-black);
}

.textCardWithBtn {
  text-align: left;
  padding: 32px 32px 15px;
  box-shadow: 0 3px 15px 5px rgba(155, 155, 155, 0.2);
  border-radius: 15px;
  margin: 15px 5px;
  min-width: 280px;
  position: relative;
}

.textCardWithBtn:hover {
  box-shadow: 3px 10px 15px 5px rgba(155, 155, 155, 0.3) !important;
}

.textCardWithBtn-link:hover {
  color: var(--light-black) !important;
}

.textCardWithBtn h5 {
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 10px;
}

.textCardWithBtn p {
  font-size: 16px;
  margin-bottom: 60px;
}

.textCardWithBtn-learnMore {
  position: absolute;
  bottom: 15px;
}

.textCardWithBtn-learnMore button {
  color: var(--blue);
  font-weight: 600;
  padding-left: 0 !important;
}

.textCardWithBtn-learnMore button:hover {
  color: var(--blue-hover);
}

.textCardWithBtn-learnMore-icon {
  font-size: 18px !important;
  margin-left: 8px;
}
