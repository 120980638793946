.countryOption {
  display: flex;
  align-items: center;
  padding: 5px 6px;
  text-align: left;
  width: auto !important;
  border-radius: 7px;
  text-decoration: none !important;
}

.countryOption:hover {
  background-color: var(--options-hover);
}

.countryOption-icon img {
  width: 22px;
  height: 17px;
  margin-right: 5px;
}

.countryOption-content h5 {
  font-size: 16px;
  margin: 0;
  margin-left: 8px;
  color: var(--light-black);
}
